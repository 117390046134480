import type {
  CheckoutData,
  CheckoutStep,
  CheckoutStepInfo,
  CustomerInfo,
  DeliveryInfo,
  DeliveryType,
} from '@/stores/checkout/types';
import { ECheckoutStep, EPayment } from '@/stores/checkout/types';
import {
  getStorageItem,
  removeStorageItem,
  setStorageItem,
} from '@/services/storage';

function emptyCheckoutData(): CheckoutData {
  return {
    activeStep: ECheckoutStep.DELIVERY_TYPE,
    returnStep: null,
    stepInfo: emptyStepInfo(),
    delivery: {},
  };
}

export function emptyStepInfo() {
  return {
    [ECheckoutStep.DELIVERY]: null,
    [ECheckoutStep.CUSTOMER_INFO]: null,
    [ECheckoutStep.PAYMENT]: null,
    [ECheckoutStep.DELIVERY_TYPE]: undefined,
    [ECheckoutStep.CONFIRM]: undefined,
    [ECheckoutStep.DONE]: undefined,
  };
}

export const getCheckoutData = (): CheckoutData =>
  getStorageItem('checkoutData') ?? emptyCheckoutData();

export const setCheckoutData = (checkoutData: CheckoutData) =>
  setStorageItem('checkoutData', checkoutData);

export const clearCheckoutData = () => removeStorageItem('checkoutData');

export const removeDeliveryInfo = (): void => {
  const checkoutData = getCheckoutData();
  checkoutData.delivery = {};
  setCheckoutData(checkoutData);
};

export const setDeliveryType = (deliveryType: DeliveryType): void => {
  const checkoutData = getCheckoutData();
  checkoutData.delivery.type = deliveryType;
  setCheckoutData(checkoutData);
};

export const setDeliveryInfo = (
  deliveryType: DeliveryType,
  deliveryInfo: DeliveryInfo,
): void => {
  const checkoutData = getCheckoutData();

  if (deliveryInfo.pickpoint) {
    checkoutData.delivery.pickpoint = deliveryInfo.pickpoint;
    delete checkoutData.delivery.address;
  } else if (deliveryInfo.address) {
    delete checkoutData.delivery.pickpoint;
    checkoutData.delivery.address = deliveryInfo.address;
  }

  if (deliveryInfo.comment) {
    checkoutData.delivery.comment = deliveryInfo.comment;
  } else {
    delete checkoutData.delivery.comment;
  }

  checkoutData.delivery.type = deliveryType;
  checkoutData.delivery.date = deliveryInfo.date;
  checkoutData.delivery.price = deliveryInfo.price;

  setCheckoutData(checkoutData);
};

export const setCustomerInfo = (customerInfo: CustomerInfo): void => {
  const checkoutData = getCheckoutData();
  checkoutData.customerInfo = customerInfo;
  setCheckoutData(checkoutData);
};

export const setPayment = (payment: EPayment): void => {
  const checkoutData = getCheckoutData();
  checkoutData.payment = payment;
  setCheckoutData(checkoutData);
};

export const setActiveStep = (step: CheckoutStep): void => {
  const checkoutData = getCheckoutData();
  checkoutData.activeStep = step;
  setCheckoutData(checkoutData);
};

export const setReturnStep = (step?: CheckoutStep | null): void => {
  const checkoutData = getCheckoutData();
  checkoutData.returnStep = step;
  setCheckoutData(checkoutData);
};

export const setStepInfo = (
  step: CheckoutStep,
  info: CheckoutStepInfo | null,
): void => {
  const checkoutData = getCheckoutData();
  checkoutData.stepInfo[step] = info;
  setCheckoutData(checkoutData);
};
