import { IdleQueue } from '@/common/idle';

const APP_STORAGE_KEY = 'readres-data';

const queue = new IdleQueue({ ensureTasksRun: true });

const storageData = JSON.parse(localStorage.getItem(APP_STORAGE_KEY) || '{}');

export const getStorageItem = (key: string) =>
  JSON.parse(JSON.stringify(storageData[key] ?? null)) ?? null;

export const setStorageItem = (
  key: string,
  value: string | number | object,
) => {
  storageData[key] = JSON.parse(JSON.stringify(value));
  saveStorage();
};

export const removeStorageItem = (key: string) => {
  delete storageData[key];
  saveStorage();
};

export const saveStorage = () => {
  queue.clearPendingTasks();

  queue.pushTask(() =>
    localStorage.setItem(APP_STORAGE_KEY, JSON.stringify(storageData)),
  );
};
