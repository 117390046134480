import { ref, type Ref, watch } from 'vue';
import { rIC } from '@/common/idle';

const isOnlinePromise = ref<Promise<boolean>>();

const isOnline: Ref<boolean> = ref(true);
const checkOnline = async () => (await isOnlinePromise.value) ?? true;

const updateOnlineStatus = async (): Promise<void> => {
  isOnlinePromise.value = new Promise((resolve) => {
    if (!navigator.onLine) {
      return resolve(false);
    }

    rIC(() =>
      fetch('/ping')
        .then((response) => response.ok)
        .catch(() => false)
        .then(resolve),
    );
  });

  isOnline.value = await checkOnline();
};

window.addEventListener('online', updateOnlineStatus);
window.addEventListener('offline', updateOnlineStatus);

type CheckOnlineHooks = {
  onOnline?: () => void;
  onOffline?: () => void;
};

export function useCheckOnline(hooks: CheckOnlineHooks = {}) {
  if (isOnlinePromise.value === undefined) {
    updateOnlineStatus();
  }

  watch(
    () => isOnline.value,
    () => {
      if (isOnline.value) {
        hooks.onOnline?.();
      } else {
        hooks.onOffline?.();
      }
    },
  );

  return {
    isOnline,
    checkOnline,
  };
}
