import type { User, UserDetails } from '@/stores/user/types';
import {
  getStorageItem,
  removeStorageItem,
  setStorageItem,
} from '@/services/storage';

export const setTokenExpiresAt = (expiresAt: string) => {
  const user = getUser();

  if (user) {
    user.tokenExpiresAt = expiresAt;
    setUser(user);
  }
};

export const getUser = (): User | null => getStorageItem('user');

export const setUser = (user: User) => setStorageItem('user', user);

export const setUserInfo = (userInfo: UserDetails) => {
  const user = getUser();

  if (user) {
    setUser({
      ...user,
      ...userInfo,
    });
  }
};

export const removeUser = () => removeStorageItem('user');
