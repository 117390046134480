import { useRootStore } from '@/stores/root';
import type { RouteLocationNormalized } from 'vue-router';

const transitions = {
  '*_CartPage': 'slide-next',
  'CartPage_*': 'slide-next',
  CartPage_BookPage: 'slide-prev',
  CheckoutPage_CartPage: 'slide-prev',
  CheckoutPage_DoneNotification: 'slide-next',
  BookPage_DoneNotification: 'slide-next',
} as Record<string, 'slide-next' | 'slide-prev'>;

export function transitionHook(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
) {
  if (useRootStore().noTransitions) {
    return;
  }

  const transition =
    transitions[`${String(from.name)}_${String(to.name)}`] ||
    transitions[`*_${String(to.name)}`] ||
    transitions[`${String(from.name)}_*`] ||
    null;

  if (transition) {
    to.meta.transition = transition;
  }
}
