import { get, type HttpClientResponse } from '@/api/http';
import type { CartInfo, Coupon, Promocode } from '@/stores/cart/types';

type CommonStateResponse = HttpClientResponse<CommonStateData>;

type CommonStateData = {
  messagesCount: number;
  favouriteBookIds: number[];
  cartInfo: CartInfo;
  coupons?: Coupon[] | null;
  promocode?: Promocode | null;
};

type CommonStateParams = {
  book_counts: Record<number, number>;
  favourite_book_ids: string;
  promocode_id: number | null;
};

export const getCommonStateData = async (
  params: CommonStateParams,
): Promise<CommonStateResponse> =>
  get<CommonStateData>('common-state', {
    ...params,
    book_counts: JSON.stringify(params.book_counts),
  });
