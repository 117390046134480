import { defineStore } from 'pinia';
import { cartInfoToBookCounts } from '@/stores/cart/utils';
import { useCartStore } from './cart';
import { getCommonStateData as apiGetCommonStateData } from '@/api/common.api';
import { useMessagesStore } from '@/stores/messages';
import { setBookIds } from '@/services/favourites.service';
import { useFavouritesStore } from '@/stores/favourites';
import { useUserStore } from '@/stores/user';
import type { HttpClientError, HttpClientResponse } from '@/api/http';

export const useRootStore = defineStore('root', {
  state: () => ({
    noTransitions: false,
  }),
  actions: {
    async loadCommonState(): Promise<HttpClientResponse> {
      const cartStore = useCartStore();
      const userStore = useUserStore();
      const messagesStore = useMessagesStore();
      const favouritesStore = useFavouritesStore();

      const params = {
        book_counts: cartInfoToBookCounts(cartStore.cartInfo),
        favourite_book_ids: favouritesStore.bookIds.join(','),
        promocode_id: cartStore.promocodeId,
      };

      const [error, response] = await apiGetCommonStateData(params);
      if (error || !response) {
        return [error || new Error('Failed to get common state data')];
      }

      messagesStore.setCount(response.messagesCount);

      const favouriteBookIds = response.favouriteBookIds.map((id) => +id);
      favouritesStore.setFavouriteIds(favouriteBookIds);
      setBookIds(favouriteBookIds);

      cartStore.setCartInfo(response.cartInfo ?? {});

      if (userStore.isLoggedIn) {
        try {
          cartStore.setCartCoupons(response.coupons);
        } catch (e) {
          return [e as HttpClientError];
        }
      }

      if (!cartStore.appliedCoupon && cartStore.promocodeId) {
        try {
          cartStore.setCartPromocode(response.promocode);
        } catch (e) {
          return [e as HttpClientError];
        }
      }

      return [null, null];
    },
    enableTransitions() {
      this.noTransitions = false;
    },
    disableTransitions() {
      this.noTransitions = true;
    },
  },
});
