import { defineStore } from 'pinia';
import { getCount, setCount } from '@/services/messages.service';
import { getUserMessages, type MessagesResponse } from '@/api/messages.api';

export type Message = {
  id: number;
  html: string;
  createdAt: string | number;
};

export type Messages = {
  items: Message[];
  totalCount: number;
};

const MESSAGES_COUNT = 10;

export const useMessagesStore = defineStore('messages', {
  state: () => ({
    messages: [] as Message[],
    count: getCount(),
  }),
  actions: {
    setCount(count: number) {
      this.count = count;
      setCount(count);
    },
    async loadMessages(): Promise<MessagesResponse> {
      const [error, messages] = await getUserMessages(MESSAGES_COUNT);

      if (!error && messages) {
        this.messages = messages.items;
        this.setCount(messages.totalCount);
      }

      return [error, messages] as MessagesResponse;
    },
  },
});
