import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import '@sass/vendor.scss';
import '@sass/main.scss';
import { defineAsyncComponent, computed, onBeforeUnmount } from 'vue';
import { useRoute } from 'vue-router';
import { useHead } from '@unhead/vue';
import { useServiceWorker } from '@/composables/serviceWorker/useServiceWorker';
import MainLayout from '@/layout/MainLayout.vue';
import { useApp } from './useApp';
import { isAuthPage } from '@/common/functions';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const route = useRoute();

const AuthPage = defineAsyncComponent(
  () => import('@/pages/auth/AuthPage.vue'),
);

const OfflinePage = defineAsyncComponent(
  () => import('@/pages/OfflinePage.vue'),
);

const UpdateToast = defineAsyncComponent(
  () => import('@/components/UpdateToast.vue'),
);

useHead({
  titleTemplate: (title?: string) =>
    !title ? 'Ридрес — Книжный интернет-магазин' : `${title} | Ридрес`,
});

const { isOnline, bootUp, shutdown } = useApp();

const serviceWorker = useServiceWorker();

const rootPage = computed(() =>
  (route.meta.authPage ?? isAuthPage()) ? AuthPage : MainLayout,
);

bootUp();

onBeforeUnmount(shutdown);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(isOnline))
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(rootPage.value), { key: 0 }))
      : (_openBlock(), _createBlock(_unref(OfflinePage), { key: 1 })),
    (_unref(serviceWorker).hasUpdate)
      ? (_openBlock(), _createBlock(_unref(UpdateToast), {
          key: 2,
          onRefresh: _unref(serviceWorker).refresh
        }, null, 8, ["onRefresh"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})