import { useRootStore } from '@/stores/root';
import { inject } from 'vue';
import { type Toast, toastKey } from '@/plugins/vue-toast-notification';
import { broadcaster, BroadcasterMessage } from '@/broadcaster';
import { isAuthPage } from '@/common/functions';
import { useCheckOnline } from '@/composables/useCheckOnline';

export function useApp() {
  const rootStore = useRootStore();
  const { errorToast } = inject(toastKey) as Toast;

  const { isOnline, checkOnline } = useCheckOnline({
    onOnline: bootUp,
    onOffline: shutdown,
  });

  async function bootUp() {
    if (!isAuthPage()) {
      const [error] = await rootStore.loadCommonState();

      if (error) {
        errorToast(error);
      }

      if (await checkOnline()) {
        await broadcaster.waitLoading();

        broadcaster
          .listen(BroadcasterMessage.FAVOURITES)
          .listen(BroadcasterMessage.ADDED_TO_CART)
          .listen(BroadcasterMessage.REMOVED_FROM_CART)
          .listen(BroadcasterMessage.COUNT_CHANGED_IN_CART)
          .listen(BroadcasterMessage.ORDER_CREATED)
          .listen(BroadcasterMessage.USER_INFO);
      }
    }
  }

  async function shutdown() {
    if (await checkOnline()) {
      broadcaster
        .stopListening(BroadcasterMessage.FAVOURITES)
        .stopListening(BroadcasterMessage.ADDED_TO_CART)
        .stopListening(BroadcasterMessage.REMOVED_FROM_CART)
        .stopListening(BroadcasterMessage.COUNT_CHANGED_IN_CART)
        .stopListening(BroadcasterMessage.ORDER_CREATED)
        .stopListening(BroadcasterMessage.USER_INFO);
    }

    broadcaster.disconnect();
  }

  return { isOnline, bootUp, shutdown };
}
