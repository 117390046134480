import { createApp, type Component } from 'vue';
import router from './router';
import { initPinia } from './stores';
import { createHead } from '@unhead/vue';
import range from './plugins/range';
import vueToastNotification from './plugins/vue-toast-notification';
import windowSize from './plugins/window-size';
import { httpClient } from '@/api/http/client';
import { initHttpClient } from '@/init/initHttpClient';
import { initLocalStorage } from './init/local-storage.init';
import phoneInputDirective from '@/directives/phone-input';

export default function (AppComponent: Component) {
  initLocalStorage();

  const app = createApp(AppComponent).use(router).use(initPinia(router));

  app
    .use(range)
    .use(vueToastNotification)
    .use(windowSize, { delay: 100 })
    .use(createHead());

  app.directive('phone-input', phoneInputDirective);

  initHttpClient(app, httpClient);

  document.getElementById('initial-loading')?.remove();

  app.mount('#app');
}
