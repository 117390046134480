import type { BroadcasterInterface } from './Broadcaster';
import { rIC } from '@/common/idle';

const original = {
  value: null as BroadcasterInterface | null,
};

let resolveLoaded: (() => void) | null = null;
const loadingProxy = new Promise<void>((resolve) => (resolveLoaded = resolve));
let isReady = false;

export const broadcaster = new Proxy(original, {
  get(target, prop: keyof BroadcasterInterface | 'waitLoading' | 'isReady') {
    if (prop === 'waitLoading') {
      return () => loadingProxy;
    }

    if (prop === 'isReady') {
      return () => isReady;
    }

    if (target.value === null) {
      return () => broadcaster;
    }

    if (typeof target.value[prop] !== 'function') {
      return target.value[prop];
    }

    return (...args: unknown[]) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const res = target.value![prop](...args);

      if (res !== target.value) {
        return res;
      }

      return broadcaster;
    };
  },
}) as unknown as BroadcasterInterface & {
  waitLoading: () => Promise<void>;
  isReady: () => boolean;
};

rIC(() => {
  import('./init')
    .then((module) => module.init())
    .then((broadcaster) => {
      original.value = broadcaster;
      resolveLoaded?.();
      isReady = true;
    });
});

export { BroadcasterMessage } from './types';
