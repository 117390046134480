import { get, type HttpClientResponse } from '@/api/http';
import type { Promocode } from '@/stores/cart/types';

export type PromocodeResponse = HttpClientResponse<Promocode | null>;

export const getPromocode = async (
  promocodeId: number,
): Promise<PromocodeResponse> =>
  get<Promocode | null>('promocode/' + +promocodeId);

export const findPromocodeByCode = async (
  code: string,
): Promise<PromocodeResponse> =>
  get<Promocode | null>('promocode/search', {
    code,
  });
