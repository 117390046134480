import { createRouter, createWebHistory } from 'vue-router';
import queryString from 'query-string';
import { routes } from './routes';
import { authGuard } from './authGuard';
import { transitionHook } from './transitions/transitionHook';
import { scrollBehavior } from './scrollBehavior';

const router = createRouter({
  history: createWebHistory(),
  routes,
  stringifyQuery: queryString.stringify,
  scrollBehavior,
});

router.beforeEach((to) => {
  const authGuardResult = authGuard(to);

  // object means that redirect route returned
  if (typeof authGuardResult === 'object') {
    return authGuardResult;
  }
});

router.afterEach(transitionHook);

export default router;
