import { defineStore } from 'pinia';
import {
  clearCart,
  getCart,
  getCouponId,
  getPromocodeId,
  remove,
  setCart,
  setCartEntry,
  setCount,
  setCouponId,
  setPromocodeId,
} from '@/services/cart.service';
import type { CartState, CartInfo, Coupon, Promocode } from './types';
import type { OrderBook } from '@/components/OrderBook/types';
import {
  type CartResponse,
  addToCart as apiAddToCart,
  getCart as apiGetCart,
  removeFromCart as apiRemoveFromCart,
  setCartBookCount as apiSetCartBookCount,
} from '@/api/cart.api';
import { cartInfoToBookCounts } from '@/stores/cart/utils';
import { useUserStore } from '@/stores/user';
import {
  rollbackOnError,
  cartBooksToCartInfo,
  syncCartBooksWithCartInfo,
} from './utils';
import { broadcaster, BroadcasterMessage } from '@/broadcaster';
import type { HttpClientResponse } from '@/api/http';
import {
  findPromocodeByCode,
  type PromocodeResponse,
} from '@/api/promocodes.api';
import { EffectEnum } from '@/stores/cart/types';

export const useCartStore = defineStore('cart', {
  state: (): CartState => ({
    cartInfo: getCart() || {},
    cartBooks: [],
    loaded: false,
    loadLock: false,
    promocodeId: getPromocodeId(),
    promocode: null,
    couponId: getCouponId(),
    coupons: [],
  }),
  getters: {
    cartInfoItemCount: (state: CartState) => (bookVariationId: number) => {
      return state.cartInfo[bookVariationId]?.count || 0;
    },
    totalBooksCount: (state: CartState) =>
      state.cartInfo
        ? Object.values(state.cartInfo).reduce((sum, val) => sum + val.count, 0)
        : 0,
    totalSum: (state: CartState) =>
      Math.trunc(
        Object.values(state.cartInfo)
          .map((entry) => (entry.basePrice || entry.price) * entry.count)
          .reduce((a, b) => a + b, 0),
      ),
    totalDiscount(state): number {
      const priceDiscount = Object.values(state.cartInfo)
        .filter((entry) => !!entry.basePrice)
        .map(
          (entry) => ((entry.basePrice as number) - entry.price) * entry.count,
        )
        .reduce((a, b) => a + b, 0);

      const bonus = this.appliedCoupon || state.promocode;

      let bonusDiscount = 0;
      if (bonus && bonus.effect === EffectEnum.PRICE) {
        bonusDiscount = (this.totalSum / 100) * (bonus.value as number);
      }

      return Math.trunc(priceDiscount + bonusDiscount);
    },
    totalDiscountedSum(): number {
      return this.totalSum - this.totalDiscount;
    },
    totalWeight: (state: CartState) =>
      (
        state.cartBooks
          .map((book) => book.weight * book.count)
          .reduce((a, b) => a + b, 0) / 1000
      ).toFixed(2),
    appliedCoupon: (state: CartState) => {
      if (state.couponId) {
        return (
          state.coupons.find((coupon) => coupon.id == state.couponId) || null
        );
      }

      return null;
    },
    cartOrderData: (state: CartState) => ({
      cartBooks: state.cartBooks,
      couponId: state.couponId,
      promocodeId: state.promocodeId,
    }),
  },
  actions: {
    async loadCart(): Promise<CartResponse> {
      if (this.loadLock) {
        return [null, []];
      }

      const userStore = useUserStore();

      this.loadLock = true;

      let error: Error | null = null;
      let data: OrderBook[] | undefined;

      if (userStore.isLoggedIn || Object.keys(this.cartInfo).length > 0) {
        [error, data] = await apiGetCart(cartInfoToBookCounts(this.cartInfo));

        if (data) {
          this.setCart(data);
        }
      }

      this.loaded = true;

      setTimeout(() => (this.loadLock = false), 5000);

      return [error, data] as CartResponse;
    },
    clearCart() {
      clearCart();

      this.cartInfo = {};
      this.cartBooks = [];
      this.loaded = false;
      this.loadLock = false;
      this.promocodeId = null;
      this.promocode = null;
      this.couponId = null;
      this.coupons = [];
    },
    setCart(books: OrderBook[]) {
      setCart(cartBooksToCartInfo(books));
      this.cartInfo = getCart();
      this.cartBooks = books;
    },
    setCartInfo(cartInfo: CartInfo) {
      setCart(cartInfo);
      this.cartInfo = getCart();
      this.cartBooks = syncCartBooksWithCartInfo(this.cartBooks, cartInfo);
    },
    addToCartInfo(book: OrderBook) {
      const bookVariationId = book.variations[0].id;

      setCartEntry(bookVariationId, {
        count: book.count,
        price: book.price,
        basePrice: book.basePrice,
      });

      this.cartInfo = getCart();
    },
    addToCartBooks(book: OrderBook) {
      const foundBookIdx = this.cartBooks.findIndex(
        (b) => b.variations[0].id == book.variations[0].id,
      );

      if (foundBookIdx !== -1) {
        this.cartBooks[foundBookIdx] = book;
      } else {
        this.cartBooks = [...this.cartBooks, book];
      }
    },
    async addToCart(book: OrderBook): Promise<HttpClientResponse> {
      const userStore = useUserStore();

      const bookVariationId = book.variations[0].id;

      const [error] = await rollbackOnError(this, async () => {
        this.addToCartInfo(book);
        this.addToCartBooks(book);

        if (userStore.isLoggedIn) {
          return await apiAddToCart(bookVariationId);
        }

        broadcaster.post(BroadcasterMessage.ADDED_TO_CART, {
          cartInfo: this.cartInfo,
          book,
        });

        return [null, null];
      });

      return (
        error ? [new Error('Ошибка при добавлении в корзину.')] : [null, null]
      ) as HttpClientResponse;
    },
    removeFromCartInfo(bookVariationId: number) {
      remove(bookVariationId);
      this.cartInfo = getCart();
    },
    removeFromCartBooks(bookVariationId: number) {
      const idx = this.cartBooks.findIndex(
        (book) => book.variations[0].id == bookVariationId,
      );

      if (idx >= 0) {
        this.cartBooks.splice(idx, 1);
      }
    },
    async removeFromCart(bookVariationId: number): Promise<HttpClientResponse> {
      const userStore = useUserStore();

      const [error] = await rollbackOnError(this, async () => {
        this.removeFromCartInfo(bookVariationId);
        this.removeFromCartBooks(bookVariationId);

        if (userStore.isLoggedIn) {
          return await apiRemoveFromCart(bookVariationId);
        }

        broadcaster.post(BroadcasterMessage.REMOVED_FROM_CART, {
          cartInfo: this.cartInfo,
          bookVariationId,
        });

        return [null, null];
      });

      return error
        ? [new Error('Не удалось удалить книгу из корзины.')]
        : [null, null];
    },
    setCartInfoItemCount(bookVariationId: number, count: number) {
      setCount(bookVariationId, count);
      this.cartInfo = getCart();
    },
    setCartBooksItemCount(bookVariationId: number, count: number) {
      const idx = this.cartBooks.findIndex(
        (book) => book.variations[0].id == bookVariationId,
      );

      if (idx >= 0) {
        this.cartBooks[idx].count = count;
      }
    },
    async setCartBookCount(
      bookVariationId: number,
      count: number,
    ): Promise<HttpClientResponse> {
      if (count <= 0) {
        return this.removeFromCart(bookVariationId);
      }

      const userStore = useUserStore();

      const [error] = await rollbackOnError(this, async () => {
        this.setCartInfoItemCount(bookVariationId, count);
        this.setCartBooksItemCount(bookVariationId, count);

        if (!userStore.isLoggedIn) {
          return [null, count];
        }

        const [error, res] = await apiSetCartBookCount(bookVariationId, count);

        const apiCount = res?.count ?? null;

        const lastStoredCount = this.cartInfoItemCount(bookVariationId);

        if (!error && apiCount !== null && lastStoredCount !== apiCount) {
          this.setCartInfoItemCount(bookVariationId, apiCount);
          this.setCartBooksItemCount(bookVariationId, apiCount);
        }

        broadcaster.post(BroadcasterMessage.COUNT_CHANGED_IN_CART, {
          cartInfo: this.cartInfo,
          bookVariationId,
          count,
        });

        return [error, apiCount ?? lastStoredCount] as HttpClientResponse;
      });

      return error
        ? [new Error('Не удалось изменить количество книг в корзине.')]
        : [null, null];
    },
    applyCoupon(couponId: number) {
      setPromocodeId(null);
      this.promocodeId = null;

      this.couponId = couponId;
      setCouponId(couponId);
    },
    async applyPromocode(code: string): Promise<PromocodeResponse> {
      const [error, promocode] = await findPromocodeByCode(code);

      if (!error && promocode) {
        setCouponId(null);
        this.couponId = null;

        this.promocode = promocode;

        setPromocodeId(promocode.id);
        this.promocodeId = promocode.id;
      }

      return [error, promocode] as PromocodeResponse;
    },
    setCartCoupons(coupons: Coupon[] | null | undefined) {
      this.coupons = coupons || [];

      if (this.couponId && !this.appliedCoupon) {
        setCouponId(null);
        this.couponId = null;

        throw Error('Истек срок действия купона');
      }
    },
    setCartPromocode(promocode: Promocode | null | undefined) {
      if (promocode) {
        this.promocode = promocode;
      } else {
        setPromocodeId(null);
        this.promocodeId = null;

        throw Error('Истек срок действия промокода');
      }
    },
    cancelBonus() {
      this.couponId = null;
      this.promocodeId = null;
      this.promocode = null;
      setCouponId(null);
      setPromocodeId(null);
    },
  },
});
