import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withModifiers as _withModifiers, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal" }
const _hoisted_2 = { class: "modal__container hide-scroll" }
const _hoisted_3 = {
  key: 0,
  class: "modal__header"
}
const _hoisted_4 = { class: "modal__body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "modal__wrapper",
        onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.$emit('close')), ["self"]))
      }, [
        _createElementVNode("button", {
          class: "modal__close-btn",
          title: "Закрыть",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
        }, [
          _createVNode(_component_svg_icon, {
            width: "21",
            height: "21",
            icon: "cross"
          }),
          _cache[3] || (_cache[3] = _createElementVNode("span", { class: "visually-hidden" }, "Закрыть", -1))
        ]),
        _createElementVNode("div", _hoisted_2, [
          (_ctx.header)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("h4", null, _toDisplayString(_ctx.header), 1),
                _createElementVNode("button", {
                  class: "modal__header-close-btn",
                  title: "Закрыть",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
                }, [
                  _createVNode(_component_svg_icon, {
                    width: "21",
                    height: "21",
                    icon: "cross"
                  }),
                  _cache[4] || (_cache[4] = _createElementVNode("span", { class: "visually-hidden" }, "Закрыть", -1))
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "default")
          ])
        ])
      ])
    ])
  ]))
}