import type { CheckoutData } from './types';
import type { CartOrderData } from '@/stores/cart/types';
import type { OrderData } from '@/api/orders.api';
import type { OrderBook } from '@/components/OrderBook/types';
import { EVariationType } from '@/components/types/book_types';
import { useCartStore } from '@/stores/cart';

export function validateCheckoutData(checkoutData: CheckoutData): void {
  if (!checkoutData.delivery.pickpoint && !checkoutData.delivery.address) {
    throw new Error('Необходимо указать информацию о доставке');
  }
  if (!checkoutData.customerInfo) {
    throw new Error('Необходимо указать контактные данные');
  }
  if (!checkoutData.payment) {
    throw new Error('Необходимо указать способ оплаты');
  }
}

export function toOrderData(
  checkoutData: CheckoutData,
  cartData: CartOrderData,
): OrderData {
  const bookInfo = cartData.cartBooks.map((book) => ({
    variationId: book.variations[0].id,
    price: book.price,
    count: book.count,
  }));

  return {
    delivery: checkoutData.delivery,
    customerInfo: checkoutData.customerInfo!,
    payment: checkoutData.payment!,
    bookInfo: bookInfo,
    couponId: cartData.couponId,
    promocodeId: cartData.promocodeId,
  };
}

export function calcDeliveryDate() {
  const hasPaperCartBooks = !!useCartStore().cartBooks.find(
    (cartBook: OrderBook) => cartBook.variationType === EVariationType.PAPER,
  );

  const date = new Date();

  if (hasPaperCartBooks) {
    date.setDate(date.getDate() + 7);
  }

  return date;
}
