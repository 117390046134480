import type { SuccessStatus, SuccessStatusResponse } from './types';
import { get, patch, type HttpClientResponse } from '@/api/http';
import type { ChangePasswordData, UserDetails } from '@/stores/user/types';

export type UserInfoResponse = HttpClientResponse<UserDetails>;

export const changePassword = async (
  formData: ChangePasswordData,
): Promise<SuccessStatusResponse> =>
  patch<SuccessStatus>('profile/password', formData);

export const changeUserInfo = async (
  userInfo: UserDetails,
): Promise<SuccessStatusResponse> =>
  patch<SuccessStatus>('profile/user', userInfo);

export const getUserInfo = async (): Promise<UserInfoResponse> =>
  get<UserDetails>('profile/user');
