import { ECheckoutStep, EDeliveryType, EPayment } from './types';

export const DELIVERY_NAME_MAP = {
  [EDeliveryType.PICKUP]: 'Самовывоз',
  [EDeliveryType.COURIER]: 'Курьер',
  [EDeliveryType.MAIL]: 'Почта',
};

export const PAYMENT_TEXT_MAP = {
  [EPayment.ONLINE]: 'Картой онлайн',
  [EPayment.CASH]: 'Наличными при получении',
  [EPayment.CARD]: 'Картой при получении',
};

export const NEXT_STEP_MAP = {
  [ECheckoutStep.DELIVERY_TYPE]: ECheckoutStep.DELIVERY,
  [ECheckoutStep.DELIVERY]: ECheckoutStep.CUSTOMER_INFO,
  [ECheckoutStep.CUSTOMER_INFO]: ECheckoutStep.PAYMENT,
  [ECheckoutStep.PAYMENT]: ECheckoutStep.CONFIRM,
  [ECheckoutStep.CONFIRM]: ECheckoutStep.DONE,
  [ECheckoutStep.DONE]: ECheckoutStep.DONE,
};
