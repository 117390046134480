import { defineStore } from 'pinia';
import {
  addBookId,
  getBookIds,
  removeBookId,
  setBookIds,
} from '@/services/favourites.service';
import type { Book } from '@/pages/book/types';
import {
  type FavouritesResponse,
  addToFavourites,
  getFavouriteBooks,
  removeFromFavourites,
} from '@/api/favourites.api';
import type { BooksResponse } from '@/api/types';
import { useUserStore } from '@/stores/user';
import { broadcaster, BroadcasterMessage } from '@/broadcaster';

export const useFavouritesStore = defineStore('favourites', {
  state: () => ({
    bookIds: getBookIds(),
    books: [] as Book[],
  }),
  getters: {
    count: (state) => state.bookIds.length,
    isFavourite: (state) => (bookId: number) => state.bookIds.includes(bookId),
  },
  actions: {
    async addToFavourites(bookId: number): Promise<FavouritesResponse> {
      const userStore = useUserStore();

      this.setFavouriteIds(addBookId(bookId));

      if (userStore.isLoggedIn) {
        const [error] = await addToFavourites(bookId);
        if (error) {
          this.setFavouriteIds(removeBookId(bookId));

          return [error];
        }
      } else {
        broadcaster.post(BroadcasterMessage.FAVOURITES, getBookIds());
      }

      return [null, getBookIds()];
    },
    async removeFromFavourites(bookId: number): Promise<FavouritesResponse> {
      const userStore = useUserStore();

      this.setFavouriteIds(removeBookId(bookId));

      if (!userStore.isLoggedIn) {
        broadcaster.post(BroadcasterMessage.FAVOURITES, getBookIds());

        return [null, getBookIds()];
      }

      const [error, bookIds] = await removeFromFavourites(bookId);

      if (error) {
        this.setFavouriteIds(addBookId(bookId));
      } else if (bookIds) {
        setBookIds(bookIds);
        this.setFavouriteIds(bookIds);
      }

      return [error, bookIds] as FavouritesResponse;
    },
    setFavouriteIds(bookIds: number[]) {
      this.bookIds = bookIds;
      this.books = this.books.filter((book) => bookIds.includes(book.id));
    },
    async loadFavourites(): Promise<BooksResponse> {
      const [error, books] = await getFavouriteBooks(this.bookIds);

      if (books) {
        const bookIds = books.map((book) => book.id);

        this.bookIds = bookIds;
        this.books = books;

        setBookIds(bookIds);
      }

      return [error, books] as BooksResponse;
    },
  },
});
