import type { OrderBook } from '@/components/OrderBook/types';

export type CheckoutData = {
  activeStep: CheckoutStep;
  returnStep?: CheckoutStep | null;
  stepInfo: Record<CheckoutStep, CheckoutStepInfo | null | undefined>;
  delivery: DeliveryData;
  customerInfo?: CustomerInfo | null;
  payment?: EPayment | null;
};

export type DeliveryData = {
  type?: DeliveryType | null;
  pickpoint?: Pickpoint | null;
  address?: DeliveryAddress | null;
  price?: number;
  date?: string;
  comment?: string | null;
};

export enum EDeliveryType {
  PICKUP = 'PICKUP',
  COURIER = 'COURIER',
  MAIL = 'MAIL',
}

export type DeliveryType =
  | EDeliveryType.PICKUP
  | EDeliveryType.COURIER
  | EDeliveryType.MAIL;

export enum EPickpointType {
  BOXBERRY = 1,
  SDEK = 2,
}

export type PickpointType = EPickpointType.BOXBERRY | EPickpointType.SDEK;

export type Pickpoint = {
  type: PickpointType;
  id: number;
  address: string;
};

export type DeliveryInfo = {
  pickpoint?: Pickpoint | null;
  address?: DeliveryAddress | null;
  date: string;
  price: number;
  comment?: string | null;
};

export enum EStatus {
  NEW = 'NEW',
  PAID = 'PAID',
  ACCEPTED = 'ACCEPTED',
  COLLECTING = 'COLLECTING',
  SENT_TO_DELIVERY = 'SENT_TO_DELIVERY',
  AT_PICKUP_POINT = 'AT_PICKUP_POINT',
  COURIER = 'COURIER',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
}

export type Status = `${EStatus}`;

export enum ELocalityType {
  CITY = 'CITY',
  SETTLEMENT = 'SETTLEMENT',
}

export type LocalityType = `${ELocalityType}`;

export type DeliveryAddress = {
  area: string;
  locality: string;
  localityType: LocalityType;
  index: number;
  street: string;
  housing?: string;
  house: string;
  apartment?: string;
};

export type CustomerInfo = {
  name: string;
  surname: string;
  email: string;
  phone: string;
};

export enum EPayment {
  ONLINE = 'ONLINE',
  CASH = 'CASH',
  CARD = 'CARD',
}

export type Payment = `${EPayment}`;

export type OrdersItem = {
  id: number;
  key: string;
  bookCount: number;
  status: Status;
  totalPrice: number;
  payment: Payment;
};

export type Order = {
  id: number;
  createdAt: string;
  acceptedAt?: string;
  status: Status;
  delivery: DeliveryData;
  customerInfo: CustomerInfo;
  payment: Payment;
  totalPrice: number;
  books: OrderBook[];
};

export enum ECheckoutStep {
  DELIVERY_TYPE = 0,
  DELIVERY = 1,
  CUSTOMER_INFO = 2,
  PAYMENT = 3,
  CONFIRM = 4,
  DONE = 5,
}

export type CheckoutStep =
  | ECheckoutStep.DELIVERY_TYPE
  | ECheckoutStep.DELIVERY
  | ECheckoutStep.CUSTOMER_INFO
  | ECheckoutStep.PAYMENT
  | ECheckoutStep.CONFIRM
  | ECheckoutStep.DONE;

export type CheckoutStepInfo = {
  value: string;
  details?: string | null;
};
