import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, Transition as _Transition, unref as _unref, withCtx as _withCtx, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"margin-top":"60px","min-height":"100vh"}
}

import { ref } from 'vue';
import { useRouter } from 'vue-router';
import TheHeader from '@/components/Header/TheHeader.vue';
import TheFooter from '@/components/Footer/TheFooter.vue';
import { setRouteTransitionActive } from '@/router/transitions/state';
import VLoader from '@/components/ui/VLoader.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'MainLayout',
  setup(__props) {

const router = useRouter();

const routerLoaded = ref(false);

router.isReady().then(() => (routerLoaded.value = true));

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(TheHeader),
    _createElementVNode("main", null, [
      (!routerLoaded.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(VLoader, { size: "medium" })
          ]))
        : (_openBlock(), _createBlock(_component_router_view, { key: 1 }, {
            default: _withCtx(({ Component, route }) => [
              _createVNode(_Transition, {
                name: route.meta.transition,
                mode: "out-in",
                onBeforeLeave: _cache[0] || (_cache[0] = ($event: any) => (_unref(setRouteTransitionActive)(true))),
                onAfterEnter: _cache[1] || (_cache[1] = ($event: any) => (_unref(setRouteTransitionActive)(false)))
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                ]),
                _: 2
              }, 1032, ["name"])
            ]),
            _: 1
          }))
    ]),
    _createVNode(TheFooter)
  ], 64))
}
}

})