import type { App } from 'vue';
import type { HttpClientInterface } from '@/api/http/client/base';
import { useRefreshTokenIfNeeded } from '@/api/http/client/hooks/beforeRequest/refreshTokenIfNeeded';
import { addCachingStrategyHeader } from '@/api/http/client/hooks/beforeRequest/addCachingStrategyHeader';
import { addSocketId } from '@/api/http/client/hooks/beforeRequest/addSocketId';
import { useRefreshTokenAndRetry } from '@/api/http/client/hooks/responseError/refreshTokenAndRetry';
import { useTokenService } from '@/services/token/tokenService';
import { useUserStore } from '@/stores/user';

export function initHttpClient(app: App, client: HttpClientInterface) {
  const userStore = useUserStore();

  const onTokenExpired = async () => {
    await userStore.logout();

    app.config.globalProperties.$errorToast(
      'Истекло время жизни токена вашего профиля. Пожалуйста, авторизуйтесь заново.',
    );
  };

  const tokenService = useTokenService(userStore, onTokenExpired);

  client.addBeforeRequestHook(useRefreshTokenIfNeeded(tokenService));

  client.addBeforeRequestHook(addCachingStrategyHeader);

  client.addBeforeRequestHook(addSocketId);

  client.setResponseErrorHook(useRefreshTokenAndRetry(tokenService));
}
