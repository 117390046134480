import { defineStore } from 'pinia';
import type {
  CheckoutData,
  CheckoutStep,
  CustomerInfo,
  DeliveryType,
  CheckoutStepInfo,
  DeliveryInfo,
} from './types';
import { ECheckoutStep, EDeliveryType, EPayment } from './types';
import {
  clearCheckoutData,
  emptyStepInfo,
  getCheckoutData,
  removeDeliveryInfo,
  setActiveStep,
  setCheckoutData,
  setCustomerInfo,
  setDeliveryInfo,
  setDeliveryType,
  setPayment,
  setReturnStep,
  setStepInfo,
} from '@/services/orders.service';
import { toOrderData, validateCheckoutData, calcDeliveryDate } from './utils';
import type { HttpClientError } from '@/api/http';
import {
  createOrder as apiCreateOrder,
  type CreateOrderResultResponse,
} from '@/api/orders.api';
import { useCartStore } from '@/stores/cart';
import {
  DELIVERY_NAME_MAP,
  PAYMENT_TEXT_MAP,
  NEXT_STEP_MAP,
} from './constants';
import {
  addressToString,
  getKeyByValue,
  phoneNumberMask,
} from '@/common/functions';

export const useCheckoutStore = defineStore('checkout', {
  state: (): CheckoutData => {
    const data = getCheckoutData();

    return {
      activeStep: data.activeStep,
      returnStep: data.returnStep,
      stepInfo: data.stepInfo,
      delivery: data.delivery,
      customerInfo: data.customerInfo,
      payment: data.payment,
    };
  },
  getters: {
    nextStep: (state) => {
      return state.returnStep || NEXT_STEP_MAP[state.activeStep];
    },
    prevStep: (state) => {
      return getKeyByValue(
        NEXT_STEP_MAP,
        state.activeStep,
      ) as CheckoutStep | null;
    },
    isClean: (state) => {
      const { activeStep, returnStep, stepInfo } = state;

      return (
        activeStep === ECheckoutStep.DELIVERY_TYPE &&
        returnStep === null &&
        JSON.stringify(stepInfo) === JSON.stringify(emptyStepInfo())
      );
    },
    getStepInfo: (state) => (step: CheckoutStep) =>
      state.stepInfo[step] || null,
    deliveryType: (state) => state.delivery?.type || null,
    deliveryDate: (state) => state.delivery?.date || null,
    deliveryAddress: (state) => state.delivery?.address || null,
    deliveryPrice: (state) => state.delivery?.price || 0,
  },
  actions: {
    async createOrder(): Promise<CreateOrderResultResponse> {
      const cartStore = useCartStore();

      try {
        validateCheckoutData(this.$state);
      } catch (error) {
        return [error as HttpClientError];
      }

      const orderData = toOrderData(this.$state, cartStore.cartOrderData);

      const [error, responseData] = await apiCreateOrder(orderData);
      if (error) {
        return [error];
      }
      if (!responseData?.order?.id || !responseData?.order?.createdAt) {
        return [new Error('Возникла ошибка при оформлении заказа')];
      }

      return [error, responseData];
    },
    setActiveStep(step: CheckoutStep) {
      this.activeStep = step;
      setActiveStep(step);
    },
    setReturnStep(step: CheckoutStep | null) {
      this.returnStep = step;
      setReturnStep(step);
    },
    async toNextStep() {
      const nextStep = this.nextStep;

      await this.router.push({
        path: `/checkout/${nextStep}`,
        state: {
          activeStep: +nextStep,
          returnStep: null,
        },
      });

      this.setActiveStep(+nextStep);
      this.setReturnStep(null);
    },
    async toPrevStep() {
      const backStep = this.returnStep || this.prevStep;

      if (backStep !== null) {
        if (window.history.state.activeStep !== undefined) {
          this.router.back();
        } else {
          this.setActiveStep(+backStep);
          this.setReturnStep(null);
        }
      }
    },
    async toEditStep(step: CheckoutStep) {
      if (!this.returnStep) {
        this.setReturnStep(this.activeStep);
      }

      this.setActiveStep(step);

      await this.router.push({
        path: `/checkout/${step}`,
        state: {
          activeStep: this.activeStep,
          returnStep: this.returnStep,
        },
      });
    },
    setStepInfo(step: CheckoutStep, stepInfo: CheckoutStepInfo | null) {
      this.stepInfo[step] = stepInfo;

      setStepInfo(step, stepInfo);
    },
    setDeliveryType(deliveryType: DeliveryType) {
      if (this.delivery.type != deliveryType) {
        this.delivery = {};
        removeDeliveryInfo();

        this.delivery.type = deliveryType;
        setDeliveryType(deliveryType);

        this.setStepInfo(ECheckoutStep.DELIVERY, null);

        this.setReturnStep(null);
      }
    },
    setDeliveryInfo(deliveryType: DeliveryType, deliveryInfo: DeliveryInfo) {
      this.setDeliveryType(deliveryType);
      this.delivery.pickpoint = deliveryInfo.pickpoint || null;
      this.delivery.address = deliveryInfo.address || null;
      this.delivery.price = deliveryInfo.price;
      this.delivery.date = deliveryInfo.date;
      this.delivery.comment = deliveryInfo.comment || null;

      setDeliveryInfo(deliveryType, deliveryInfo);
    },
    updateDeliveryDate() {
      const date = calcDeliveryDate();
      this.delivery.date = date.toISOString();
    },
    setPickpointInfo(deliveryInfo: DeliveryInfo) {
      this.setDeliveryInfo(EDeliveryType.PICKUP, deliveryInfo);

      const stepInfo = {
        value: DELIVERY_NAME_MAP[EDeliveryType.PICKUP],
        details: deliveryInfo.pickpoint!.address,
      };

      this.setStepInfo(ECheckoutStep.DELIVERY, stepInfo);
    },
    setCourierInfo(deliveryInfo: DeliveryInfo) {
      this.setDeliveryInfo(EDeliveryType.COURIER, deliveryInfo);

      const stepInfo = {
        value: DELIVERY_NAME_MAP[EDeliveryType.COURIER],
        details: addressToString(deliveryInfo.address),
      };

      this.setStepInfo(ECheckoutStep.DELIVERY, stepInfo);
    },
    setMailInfo(deliveryInfo: DeliveryInfo) {
      this.setDeliveryInfo(EDeliveryType.MAIL, deliveryInfo);

      const stepInfo = {
        value: DELIVERY_NAME_MAP[EDeliveryType.MAIL],
        details: addressToString(deliveryInfo.address),
      };

      this.setStepInfo(ECheckoutStep.DELIVERY, stepInfo);
    },
    setCustomerInfo(customerInfo: CustomerInfo) {
      this.customerInfo = customerInfo;
      setCustomerInfo(customerInfo);

      const stepInfo = {
        value: `${customerInfo.surname} ${customerInfo.name}`,
        details: `${customerInfo.email}, ${phoneNumberMask(customerInfo.phone)}`,
      };

      this.setStepInfo(ECheckoutStep.CUSTOMER_INFO, stepInfo);
    },
    setPayment(payment: EPayment) {
      this.payment = payment;
      setPayment(payment);

      const stepInfo = {
        value: PAYMENT_TEXT_MAP[payment],
      };

      this.setStepInfo(ECheckoutStep.PAYMENT, stepInfo);
    },
    resetCheckout() {
      const resetCheckoutData = {
        ...getCheckoutData(),
        activeStep: ECheckoutStep.DELIVERY_TYPE,
        returnStep: null,
        stepInfo: emptyStepInfo(),
      };

      this.$patch(resetCheckoutData);

      setCheckoutData(resetCheckoutData);
    },
    clearCheckout() {
      clearCheckoutData();
      this.$patch(getCheckoutData());
    },
  },
});
