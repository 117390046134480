import type { SuccessStatus, SuccessStatusResponse } from './types';
import { get, post, patch, type HttpClientResponse } from '@/api/http';
import { EStatus } from '@/stores/checkout/types';
import type {
  CheckoutStep,
  DeliveryType,
  CustomerInfo,
  DeliveryData,
  Order,
  OrdersItem,
  Payment,
} from '@/stores/checkout/types';

export type CreateOrderResult = {
  success: boolean;
  order: {
    id: number;
    key: string;
    createdAt: string;
  };
};

type BookInfo = Array<{
  variationId: number;
  price: number;
  count: number;
}>;

export type OrderData = {
  delivery: DeliveryData;
  customerInfo: CustomerInfo;
  payment: Payment;
  bookInfo: BookInfo;
  couponId: number | null;
  promocodeId: number | null;
};

type OrdersItemsResponse = HttpClientResponse<OrdersItem[]>;
type OrderResponse = HttpClientResponse<Order>;
export type CreateOrderResultResponse = HttpClientResponse<CreateOrderResult>;
export type InitCheckoutSessionResponse = HttpClientResponse<{
  recreated: boolean;
}>;

export const getOrdersList = async (): Promise<OrdersItemsResponse> =>
  get<OrdersItem[]>('orders');

export const getOrder = async (key: string): Promise<OrderResponse> =>
  get<Order>('orders/' + key);

export const cancelOrder = async (
  key: string,
): Promise<SuccessStatusResponse> =>
  patch<SuccessStatus>('orders/' + key, { status: EStatus.CANCELED });

export const createOrder = async (
  orderData: OrderData,
): Promise<CreateOrderResultResponse> =>
  post<CreateOrderResult>('orders', { orderData });

export const initCheckoutSession = async (params: {
  smallView: boolean;
  prefilledDeliveryType?: DeliveryType | null;
  isCustomerInfoPrefilled: boolean;
}): Promise<InitCheckoutSessionResponse> =>
  get<{ recreated: boolean }>('orders/checkout', {
    s: +params.smallView || undefined,
    d: params.prefilledDeliveryType || undefined,
    c: +params.isCustomerInfoPrefilled || undefined,
  });

export const addStepToCheckoutSession = async (
  step: CheckoutStep,
  smallView: boolean,
): Promise<HttpClientResponse<''>> =>
  get<''>(`orders/step/${step}`, {
    s: +smallView || undefined,
  });
