import { markRaw } from 'vue';
import { createPinia } from 'pinia';
import { PiniaLogger } from 'pinia-logger';
import type { Router } from 'vue-router';

export function initPinia(router: Router) {
  const debug = process.env.NODE_ENV !== 'production';

  const pinia = createPinia();

  if (debug) {
    pinia.use(
      PiniaLogger({
        expanded: false,
        disabled: !debug,
      }),
    );
  }

  pinia.use(() => ({
    router: markRaw(router),
  }));

  return pinia;
}
