import { defineComponent as _defineComponent } from 'vue'
import { setBlockTracking as _setBlockTracking, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

type LoaderSize = 'small' | 'medium' | 'large';

interface Props {
  size?: LoaderSize;
  sticky?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'VLoader',
  props: {
    size: { default: 'small' },
    sticky: { type: Boolean, default: false }
  },
  setup(__props: any) {

const modifierClasses = {
  small: '',
  medium: 'loading--md',
  large: 'loading--lg',
};



const classes: string[] = [];

if (modifierClasses[__props.size]) {
  classes.push(modifierClasses[__props.size]);
}

if (__props.sticky) {
  classes.push('loading--sticky');
}

return (_ctx: any,_cache: any) => {
  return _cache[0] || (
    _setBlockTracking(-1),
    (_cache[0] = _createElementVNode("div", {
      class: _normalizeClass(["loading", classes])
    })).cacheIndex = 0,
    _setBlockTracking(1),
    _cache[0]
  )
}
}

})