import type { CartInfo } from './types';
import type { OrderBook } from '@/components/OrderBook/types';
import type { HttpClientResponse } from '@/api/http';
import { getCart, setCart } from '@/services/cart.service';
import { useCartStore } from '@/stores/cart';

export async function rollbackOnError(
  cartStore: ReturnType<typeof useCartStore>,
  fn: () => Promise<HttpClientResponse>,
): Promise<HttpClientResponse> {
  const cartBooks = cartStore.cartBooks;
  const cartInfo = getCart();

  const res = await fn();

  // if error
  if (res[0]) {
    cartStore.cartBooks = cartBooks;
    cartStore.cartInfo = cartInfo;
    setCart(cartInfo);
  }

  return res;
}

export function cartBooksToCartInfo(books: OrderBook[]): CartInfo {
  return Object.assign(
    {},
    ...books.map(({ variations, count, price, basePrice }) => ({
      [variations[0].id]: { count, price, basePrice },
    })),
  );
}

export function cartInfoToBookCounts(
  cartInfo: CartInfo,
): Record<number, number> {
  return Object.fromEntries(
    Object.entries(cartInfo).map(([bookVariationId, item]) => [
      +bookVariationId,
      item.count,
    ]),
  );
}

export function syncCartBooksWithCartInfo(
  books: OrderBook[],
  cartInfo: CartInfo,
): OrderBook[] {
  return books
    .filter((book) => book.variations[0].id in cartInfo)
    .map((book) => {
      const { count, price, basePrice } = cartInfo[book.variations[0].id];

      return { ...book, count, price, basePrice };
    });
}
