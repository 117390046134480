import { defineStore } from 'pinia';
import {
  getUser,
  removeUser,
  setUser,
  setUserInfo,
} from '@/services/user.service';
import type {
  LoginUserData,
  RegisterUserData,
  SendPasswordResetData,
  UserDetails,
  ChangePasswordData,
  ResetPasswordData,
} from './types';
import {
  type LoginResponse,
  type ResetResponse,
  login as apiLogin,
  logout as apiLogout,
  register as apiRegister,
  resetPassword as apiResetPassword,
  sendResetLink as apiSendResetLink,
} from '@/api/auth.api';
import { broadcaster, BroadcasterMessage } from '@/broadcaster';
import { useRootStore } from '@/stores/root';
import { useCheckoutStore } from '@/stores/checkout';
import {
  changePassword as apiChangePassword,
  changeUserInfo as apiChangeUserInfo,
  getUserInfo as apiGetUserInfo,
  type UserInfoResponse,
} from '@/api/profile.api';
import type { SuccessStatusResponse } from '@/api/types';

export const useUserStore = defineStore('user', {
  state: () => ({
    user: getUser(),
  }),
  getters: {
    isLoggedIn: (state) => !!state.user,
    userDetails: (state): UserDetails | null => {
      if (state.user) {
        return {
          name: state.user.name,
          email: state.user.email,
          phone: state.user.phone,
        };
      }

      return null;
    },
  },
  actions: {
    async login({
      userData,
    }: {
      userData: LoginUserData;
    }): Promise<LoginResponse> {
      const rootStore = useRootStore();
      const checkoutStore = useCheckoutStore();

      this.user = null;

      const [error, res] = await apiLogin(userData);

      if (res) {
        const user = {
          tokenExpiresAt: res.expiresAt,
          ...res.user,
          id: +res.user.id,
        };

        setUser(user);

        this.user = user;

        checkoutStore.clearCheckout();

        setTimeout(() => {
          rootStore.loadCommonState();

          broadcaster
            .listen(BroadcasterMessage.FAVOURITES)
            .listen(BroadcasterMessage.ADDED_TO_CART)
            .listen(BroadcasterMessage.REMOVED_FROM_CART)
            .listen(BroadcasterMessage.COUNT_CHANGED_IN_CART)
            .listen(BroadcasterMessage.ORDER_CREATED)
            .listen(BroadcasterMessage.USER_INFO);
        }, 0);
      }

      return [error, res] as LoginResponse;
    },
    async logout() {
      const checkoutStore = useCheckoutStore();

      await apiLogout();

      this.clearUser();

      checkoutStore.clearCheckout();
    },
    clearUser() {
      removeUser();
      this.user = null;
    },
    async register(
      userData: RegisterUserData,
      couponId: number | null,
    ): Promise<SuccessStatusResponse> {
      this.user = null;

      return await apiRegister(userData, couponId);
    },
    async sendPasswordReset(
      formData: SendPasswordResetData,
    ): Promise<ResetResponse> {
      return apiSendResetLink(formData);
    },
    async resetPassword(formData: ResetPasswordData): Promise<ResetResponse> {
      return apiResetPassword(formData);
    },
    async changePassword(
      formData: ChangePasswordData,
    ): Promise<SuccessStatusResponse> {
      return apiChangePassword(formData);
    },
    setUserInfo(userInfo: UserDetails) {
      this.user = {
        ...this.user!,
        ...userInfo,
      };
      setUserInfo(userInfo);
    },
    async updateUserInfo(
      userInfo: UserDetails,
    ): Promise<SuccessStatusResponse> {
      const [error, response] = await apiChangeUserInfo(userInfo);

      if (response?.success) {
        this.setUserInfo(userInfo);
      }

      return [error, response] as SuccessStatusResponse;
    },
    async loadUserInfo(): Promise<UserInfoResponse> {
      const [error, response] = await apiGetUserInfo();

      if (response) {
        this.setUserInfo(response);
      }

      return [error, response] as UserInfoResponse;
    },
  },
});
